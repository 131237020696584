import React from 'react';
import ResponsiveAppBar from './Appbar';
import Footer from './Footer';

const PrivacyPolicy = () => {
  const sectionStyle = {
    maxWidth: '1100px',
    padding: '20px', 
  };



  return (
    <div class="uk-margin-left" style={{marginTop:"100px"}}>
      <div>
        <div style={{ letterSpacing: '4px' }}>
          <h5>This is the Privacy Policy for this Website, one of our main piorites is the privacy of our visitors.</h5>
        </div>
      </div>

      <div style={sectionStyle}>
    <div>
      <h2>Privacy Policy</h2>
      <br></br>
    <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us</p>
    <br></br>
    <p>This Privacy Policy only applies to our online activities and is applicable to visitors to our website in relation to information shared and/or collected in this website. This policy does not apply to any information gathered offline or through channels other than this website.</p>  
    </div>
  

    <div id="about">
      <h2>Consent</h2>
      <br></br>
      <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
      <br></br>
      


<div style={sectionStyle}>

  
    <div id="about">
      <h2>Information we collect</h2><br></br>
     <div>
      <p>The personal information that you are asked to provide, as well as the reasons for asking for it, will be made clear to you at the point where we ask for it.</p>
      <p>We may receive additional information about you if you contact us directly, such as your name, email address, phone number, the contents of any message and/or attachments you send us, and any other information you choose to provide.</p>
      <p>When you create an Account, we may request contact information from you, such as your name, company name, address, email address, and phone number.</p>
    </div>
    </div>
</div>

<div style={sectionStyle}>

  
    <div id="about">
      <h2>How we use your information</h2><br></br>
     <div>
      <p>We use the information we collect in various ways, including to:We use the information we collect in various ways, including to:</p>
      <ul>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>Communicate with you, either directly or through one of our partners, for customer service, updates and other website-related information, and marketing and promotional purposes.</li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>
        </ul>
    </div>
    </div>
</div>


<div style={sectionStyle}>


  <div id="about">
    <h2>Log Files</h2><br></br>
   <div>
    <p>This website employs log files in accordance with industry standards. When people visit websites, these files record their activities. This is something that all hosting companies do as part of their analytics. Internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks are among the data collected by log files. These are not linked to any personally identifiable information. The information is used to analyze trends, administer the site, track users' movements on the website, and gather demographic information.</p>
  </div>
  </div>
</div>


<div style={sectionStyle}>
  <div id="about">
    <h2>Cookies and Web Beacons</h2><br></br>
   <div>
    <p>This website, like any other, makes use of 'cookies.' These cookies are used to save information such as visitors' preferences and the pages on the website that they accessed or visited. The data is used to improve the user experience by tailoring our web page content to visitors' browser types and/or other information.</p>
  </div>
  </div>
</div>

<div style={sectionStyle}>
  <div id="about">
    <h2>Google DoubleClick DART Cookie</h2><br></br>
   <div>
    <p>Google is one of our site's third-party vendors. It also employs DART cookies to serve advertisements to our site visitors based on their visits to www.website.com and other websites on the internet. Visitors can, however, decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the URL below.
      <br></br>
      <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>
    </p>
  </div>
  </div>
</div>
<div style={sectionStyle}>
  <div id="about">
    <h2>Our Advertising Partners</h2><br></br>
   <div>
    <p>Some of our advertisers may use cookies and web beacons. The following are our advertising partners. For their policies on user data, each of our advertising partners has their own Privacy Policy. We've included a link to their Privacy Policies below for your convenience.</p>
    <br></br>
    <ul>
      <li>
          <p>Google</p>
          <p><a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>
      </li>
  </ul>
  </div>
  </div>
</div>

<div style={sectionStyle}>


  <div id="about">
    <h2>Advertising Partners Privacy Policies</h2><br></br>
   <div>
    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of this website.</p>
    <br></br>
    <p>Third-party ad servers or ad networks employ technologies such as cookies, JavaScript, or Web Beacons in their respective advertisements and links displayed on hellohtml, which are delivered directly to users' browsers. When this happens, they automatically receive your IP address. These technologies are used to assess the effectiveness of their advertising campaigns and/or to tailor the advertising content you see on websites you visit.</p>
    <br></br>
    <p>Note that this website has no access to or control over these cookies that are used by third-party advertisers.</p>
  </div>
  </div>
</div>

<div style={sectionStyle}>


  <div id="about">
    <h2>Third Party Privacy Policies</h2><br></br>
   <div>
    <p>The Privacy Policy of this website does not apply to other advertisers or websites. As a result, we recommend that you read the Privacy Policies of these third-party ad servers for more information. It could include their practices as well as instructions on how to opt out of certain options.</p>
    <br></br>
    <p>You can choose to disable cookies through your individual browser options. More detailed information about cookie management with specific web browsers can be found on the websites of the browsers.</p>
  </div>
  </div>
</div>

<div style={sectionStyle}>
  <div id="about">
    <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2><br></br>
   <div>
    <p>Under the CCPA, among other rights, California consumers have the right to:</p>
    <br></br>
    <p>Request that a company that collects a consumer's personal data disclose the categories and specific pieces of personal data that the company has gathered about the consumer.</p>
    <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
    <p>Request that a company that sells a consumer's personal information not sell the consumer's personal information again.</p>
    <p>If you submit a request, we have one month to respond. Please contact us if you wish to exercise any of these rights.</p>
  </div>
  </div>
</div>

<div style={sectionStyle}>
  <div id="about">
    <h2>GDPR Data Protection Rights</h2><br></br>
   <div>
<p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
<p>The right to access –  You have the right to obtain copies of your personal information. For this service, we may charge you a small fee.</p>
<p>The right to rectification – You have the right to request that any information that you believe is incorrect be corrected. You also have the option of requesting that we complete any information you believe is missing.</p>
<p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
<p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
<p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
<p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
<p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

  </div>
  </div>
</div>


<div style={sectionStyle}>
  <div id="about">
    <h2>Children's Information</h2><br></br>
   <div>
<p>Another priority is to provide protection for children while they use the internet. We encourage parents and guardians to watch, participate in, monitor, and guide their children's online activity.</p>
<p>This website does not intentionally collect Personal Identifiable Information from children under the age of 13. If you believe your child provided this type of information on our website, please contact us immediately, and we will do our best to promptly remove such information from our records.</p>

  </div>
  </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default function Privacy() {
  return (
    <div>
      <ResponsiveAppBar />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
}
