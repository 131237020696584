import React, { useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import ResponsiveAppBar from './Appbar'; 
// import Footer from './Footer'; 
import Preloader from './Preloader';
import './dailytarot.css';
const numberOfCards = 23;

const cardVariants = {
  selected: (i) => ({
    scale: 1.1,
    x: i * 5,
    y: -50,
    transition: { duration: 0.2 },
  }),
  hoverCard: (i) => ({
    scale: 1.1,
    x: i * 5,
    y: -30,
    transition: { duration: 0.2 },
  }),
  notSelected: (i) => ({
    x: 0,
    boxShadow: 'rgba(0, 0, 0, 0.3) -4px 0px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
    transition: { duration: 0.2 },
  }),
  spreadOut: (i) => ({
    x: i * 5,
    boxShadow: 'rgba(0, 0, 0, 0.3) -4px 0px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
    transition: { duration: 0.35 },
  }),
};


const DailyTarot = () => {
  const [selectedCards, setSelectedCards] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState('Choose a tarot card for your current situation');
  const containerRef = useRef();
  const cardRefs = useRef(new Array(numberOfCards).fill(null));
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  // const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth } = containerRef.current;
      const halfScroll = (scrollWidth - clientWidth) / 2;
      containerRef.current.scrollLeft = halfScroll;
    }
  }, []);
  

  

  const selectCard = (card) => {
   
    if (selectedCards.length < 5 && !selectedCards.includes(card)) {
      setSelectedCards([...selectedCards, card]);

     if(selectedCards.length === 0){setSnackbarMessage("Choose a tarot card for your love life");}
     if(selectedCards.length === 1){setSnackbarMessage("Choose a tarot card for your career life");}
     if(selectedCards.length === 2){setSnackbarMessage("Choose a tarot card for your finances condition");}
     if(selectedCards.length === 3){setSnackbarMessage("Choose a tarot card for your health condition");}      
     if(selectedCards.length === 4){
      setSnackbarOpen(false); 
      window.location.href = "/home/tarot/dailytarot-result";
    }      
    }
  };


  return (
    <div>
      isLoading ? (
        <Preloader />
      ) :
      <div>   
        <div className="flashcards uk-position-center" onMouseDown={(e) => e.preventDefault()}>
        <h3 className="uk-text-bold uk-title">Daily Tarot Reading</h3>
          <div className="flashcards__container" ref={containerRef}>
            {Array.from({ length: numberOfCards }).map((_, i) => (
              <motion.div
                className={`card ${selectedCards.includes(i + 1) ? 'selected' : ''}`}
                key={i + 1}
                ref={(el) => (cardRefs.current[i] = el)}
                onClick={() => selectCard(i + 1)}
                variants={cardVariants}
                animate={selectedCards.includes(i + 1) ? 'selected' : 'spreadOut'}
                initial="notSelected"
                custom={i}
                whileHover={selectedCards.includes(i + 1) ? 'selected' : 'hoverCard'}
              />
            ))}
          </div>
  
          <Snackbar open={snackbarOpen}>
            <SnackbarContent message={snackbarMessage} />
          </Snackbar>
        </div>
        </div>
      {/* )} */}
    </div>
  );
  
};

export default function Home() {
  return (
    <div>
      <ResponsiveAppBar />   
      <DailyTarot />
      {/* <Footer/> */}
    </div>
  );
}
