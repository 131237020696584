import React, { useState, useEffect } from 'react';

import ResponsiveAppBar from './Appbar';
import Footer from './Footer'; 
import Preloader from './Preloader';

function Myrand(max, min) {
  const arr = [];
  for (var i = 0; i < max; i++) {
    var x = Math.floor(Math.random() * max) + min;
    if (arr.includes(x)) {
      i = i - 1;
    } else {
      if (x <= max) {
        arr.push(x);
      }
    }
  }
  return arr;
}



  function DailyTarotResult() {
    const [data, setData] = useState([]);
    const [card, setCard] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = localStorage.getItem('selectedLanguage');


    useEffect(() => {
        if (window.performance) {
            if (performance.navigation.type === 1) {
                window.location.href = '/home/tarot/daily-tarot';
            }
          }
      }, []);
     
        
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}majorcards.json`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          const arr = Myrand(21, 0);
  
          const generalnum = arr[0];
          const generalval = Object.values(result.tarot_deck[generalnum].type);
          const generalname = result.tarot_deck[generalnum].name;
          const generalcard = generalval[Math.floor(Math.random() * generalval.length)];
  
          const lovenum = arr[1];
          const loveval = Object.values(result.tarot_deck[lovenum].type);
          const lovename = result.tarot_deck[lovenum].name;
          const lovecard = loveval[Math.floor(Math.random() * loveval.length)];
  
          const careernum = arr[2];
          const careerval = Object.values(result.tarot_deck[careernum].type);
          const careername = result.tarot_deck[careernum].name;
          const careercard = careerval[Math.floor(Math.random() * careerval.length)];
  
          const financesnum = arr[3];
          const financesval = Object.values(result.tarot_deck[financesnum].type);
          const financesname = result.tarot_deck[financesnum].name;
          const financescard = financesval[Math.floor(Math.random() * financesval.length)];
  
          const healthnum = arr[4];
          const healthval = Object.values(result.tarot_deck[healthnum].type);
          const healthname = result.tarot_deck[healthnum].name;
          const healthcard = healthval[Math.floor(Math.random() * healthval.length)];
  
          setData({generalcard,lovecard,careercard,financescard,healthcard});
          setCard({generalname,lovename,careername,financesname,healthname});
          setLoading(false);
          
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
  
      fetchData();
    }, [lang]);
    

    useEffect(() => {
      if (lang === 'mm') {
        // Replace text as needed for the MM language
        document.querySelectorAll('.main h4').forEach((element) => {
          switch (element.innerText) {
            case 'Your current situation':
              element.innerText = 'သင့်လတ်တလော အခြေအနေ';
              break;
            case 'Your current love life':
              element.innerText = 'လက်ရှိ အချစ်ရေး';
              break;
            case 'Your current career situation':
              element.innerText = 'အလုပ်အကိုင် အခြေအနေ';
              break;
            case 'Your finances situation':
              element.innerText = 'ဘဏ္ဍာရေးနှင့်စီးပွားရေး';
              break;
            case 'Your health':
              element.innerText = 'ကျန်းမာရေး';
              break;
            default:
              break;
          }
        });
      }
    }, [lang]);
  
    if (isLoading) {
      return <div>Loading...</div>;
    }
  
    return (
      <div>
        {isLoading ? (
          <Preloader />
        ) : (
          data && (
  <div className="container main" style={{ marginTop: '70px' }}>
  <div className="uk-card uk-card-secondary uk-grid-collapse uk-child-width-1-2@s uk-margin uk-grid uk-grid-stack" uk-grid="">
   <div className="uk-card-media-left uk-cover-container uk-first-column" style={{ margin:'50px auto',width: '220px', height: '400px' }}>
      <img style={{ width: '100%', height: '100%' }} src={data.generalcard.img} alt={data.generalcard.generalname} uk-cover />
      
    </div>
    <div className="uk-grid-margin uk-first-column">
      <div className="uk-card-body">
        <h4 className="uk-card-title">{card.generalname}</h4>
        <br />
        <p style={{ whiteSpace: 'pre-line' }}>{data.generalcard.general}</p>
      </div>
    </div>
  </div>
  
  <div className="uk-card uk-card-secondary uk-grid-collapse uk-child-width-1-2@s uk-margin uk-grid uk-grid-stack" uk-grid="">
   <div className="uk-card-media-left uk-cover-container uk-first-column" style={{ margin:'50px auto',width: '220px', height: '400px' }}>
      <img style={{ width: '100%', height: '100%' }} src={data.lovecard.img} alt={card.lovename} uk-cover />
      
    </div>
    <div className="uk-grid-margin uk-first-column">
      <div className="uk-card-body">
        <h4 className="uk-card-title">{card.lovename}</h4>
        <br />
        <p style={{ whiteSpace: 'pre-line' }}>{data.lovecard.love}</p>
      </div>
    </div>
  </div>
  <div className="uk-card uk-card-secondary uk-grid-collapse uk-child-width-1-2@s uk-margin uk-grid uk-grid-stack" uk-grid="">
   <div className="uk-card-media-left uk-cover-container uk-first-column" style={{ margin:'50px auto',width: '220px', height: '400px' }}>
      <img style={{ width: '100%', height: '100%' }} src={data.careercard.img} alt={card.careername} uk-cover />
      
    </div>
    <div className="uk-grid-margin uk-first-column">
      <div className="uk-card-body">
        <h4 className="uk-card-title">{card.careername}</h4>
        <br />
        <p style={{ whiteSpace: 'pre-line' }}>{data.careercard.career}</p>
      </div>
    </div>
  </div>
  <div className="uk-card uk-card-secondary uk-grid-collapse uk-child-width-1-2@s uk-margin uk-grid uk-grid-stack" uk-grid="">
   <div className="uk-card-media-left uk-cover-container uk-first-column" style={{ margin:'50px auto',width: '220px', height: '400px' }}>
      <img style={{ width: '100%', height: '100%' }} src={data.financescard.img} alt={card.finanesname} uk-cover />
      
    </div>
    <div className="uk-grid-margin uk-first-column">
      <div className="uk-card-body">
        <h4 className="uk-card-title">{card.financesname}</h4>
        <br />
        <p style={{ whiteSpace: 'pre-line' }}>{data.financescard.finances}</p>
      </div>
    </div>
  </div>
  <div className="uk-card uk-card-secondary uk-grid-collapse uk-child-width-1-2@s uk-margin uk-grid uk-grid-stack" uk-grid="">
   <div className="uk-card-media-left uk-cover-container uk-first-column" style={{ margin:'50px auto',width: '220px', height: '400px' }}>
      <img style={{ width: '100%', height: '100%' }} src={data.healthcard.img} alt={card.healthname} uk-cover />
      
    </div>
    <div className="uk-grid-margin uk-first-column">
      <div className="uk-card-body">
        <h4 className="uk-card-title">{card.healthname}</h4>
        <br />
        <p style={{ whiteSpace: 'pre-line' }}>{data.financescard.health}</p>
      </div>
    </div>
  </div>
</div>


          )
        )}
      </div>
    );
    
};
  

  export default function DailyTarotResultReading() {
    return (
      <div>
        <ResponsiveAppBar />
        <DailyTarotResult />
        <Footer/>
      </div>
    );
  }
  