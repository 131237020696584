import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ResponsiveAppBar from './Appbar'; 
import Preloader from './Preloader';
import Footer from  './Footer';

const MediaControlCard = () => {
    const [data, setData] = useState([]);
    const [path, setPath] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = localStorage.getItem('selectedLanguage');
    
    const gotoMahaboteDetails = (index) => {
      const next = path[index].title;
      const formattedTitle = next.toLowerCase().replace(/ /g, '-');
      window.location.href = `/home/mahabote/${formattedTitle}`; 
    };
    
    useEffect(() => {
      const fetchPath = async () => {
         try {
           const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/engmaincategory.json`);
           if (!response.ok) {throw new Error('Network response was not ok');}
           const res = await response.json();
           setPath(res.subcategory[1].Mahabote);
         } catch (error) {
           console.error('Error fetching data:', error);
         }
       };
       fetchPath();
     }, []);
    
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}maincategory.json`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setData(result.subcategory[1].Mahabote);setLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);setLoading(true);
          }
        };
        fetchData();
      }, [lang]);


return (
    <div>
    {isLoading ? (
        <Preloader />
        ) : (
            <Box sx={{ flexGrow: 1, margin: '6rem' }}>
            <h3 class="uk-text-bold uk-title">Mahabote Reading</h3>   
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 6, md: 6 }}>
            {data.map((item, index) => (
                <Grid item xs={6} sm={6} md={3} key={item}>
                
                <div  onClick={() => gotoMahaboteDetails(index)} class="uk-card uk-card-hover uk-card-default uk-card-body" style={{"border-radius":"1rem"}}>
                <div class="uk-width-auto uk-float-right">
                <img class="uk-border-circle" width="70" height="70" src={item.logo} alt={item.title}/>
                </div>
                <div class="uk-width-expand">
                <h4 class="uk-margin-remove-bottom homepghead1">{item.title}</h4>
                <p class="uk-text-meta homepgtext1">{item.desc}</p>
                </div>
                </div>
                </Grid>
                ))}
                </Grid>
                </Box>
                )}
                </div>
                );
            };
            
            
            
            
            export default function Home() {
                return (
                    <div>
                    <ResponsiveAppBar />   
                    <MediaControlCard />
                    <Footer/>
                    </div>
                    );
                }
                