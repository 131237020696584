import React, { useState, useEffect } from 'react';
import ResponsiveAppBar from './Appbar';
import Preloader from './Preloader';
import Footer from  './Footer';

const Birthdate = () => {

  const lang = localStorage.getItem('selectedLanguage') || 'mm';
  const dob = localStorage.getItem('baydinwebdob');

  const [data, setData] = useState([]);
  const [res,setRes] = useState([]);
  const [type,setType] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (dob === null) {window.location.href = "/login";}
      else{
      const birthdate = dob.split('-')[0];  
      try {
        const response = await fetch(`https://us-central1-nfc-project-e3961.cloudfunctions.net/function-1/getnumBirthdate?birthdate=${birthdate}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        const birthNum = result.birthNum;
        setRes(birthNum.toString());setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);setLoading(true);
      }}
    };
    fetchData();
  });

  useEffect(() => {
    const fetchRes = async () => {
      try {
        const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}numerology_birthdate.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        for (const key in result.birth_date) {
          if (result.birth_date[key].number === res) {
            setData(result.birth_date[key].number);
            setType(result.birth_date[key].type.meaning);   
            setLoading(false);break; 
         
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);setLoading(true);
      }
    };
    fetchRes();
  }, [lang,res]);
 

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        data && (
          <div class="uk-card uk-card-default uk-width-1-1@m" style={{"marginTop":"70px"}}>
          <div class="uk-card-header">
              <div class="uk-grid-small" uk-grid>
                  <div class="uk-width-expand uk-text-center">
                      <h4 class="uk-card-title uk-margin-remove-bottom uk-margin-top">Your Birth Number Is {data}</h4>
                  </div>
              </div>
          </div>
          <div class="uk-card-body">
          <p style={{ whiteSpace: 'pre-line' }}>{type}</p>
          </div>
          <div class="uk-card-footer">
              <button class="uk-button uk-button-text">Share</button>
          </div>
      </div>
        )
      )}
    </div>
  );
};

export default function BirthdateReading() {
  return (
    <div>
      <ResponsiveAppBar />
      <Birthdate />
      <Footer/>
    </div>
  );
}
