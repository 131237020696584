import React, { useState, useEffect } from 'react';
import ResponsiveAppBar from './Appbar';
import Preloader from './Preloader';
import Footer from './Footer';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const WesternZodiac = () => {
  const lang = localStorage.getItem('selectedLanguage') || 'mm';
  const dob = localStorage.getItem('baydinwebdob');

  const [data, setData] = useState({});
  const [res, setRes] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState('panel1');

  useEffect(() => {
    const fetchData = async () => {
      if (dob === null) {
        window.location.href = '/login';
      } else {
        try {
          const response = await fetch(`https://us-central1-nfc-project-e3961.cloudfunctions.net/function-1/getwesternZodiac?birthday=${dob}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setRes(result.sign);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(true);
        }
      }
    };
    fetchData();
  }, [dob]);

  useEffect(() => {
    const fetchRes = async () => {
      try {
        const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}zodiacsigns.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        for (const key in result.sign) {
          if (result.sign[key].name === res) {
            const updatedData = result.sign[key];
            setData(updatedData);
            setLoading(false);
            break;
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(true);
      }
    };
    fetchRes();
  }, [lang, res]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        data && (
          <div class="uk-margin-left uk-margin-right" style={{ marginTop: "100px" }}>
            <h3>{data.name}</h3>
            <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
              <Typography style={{ fontWeight: 'bold' }}>Facts</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ul>
                    <li>Element - {data.facts && data.facts.Element} </li>
                    <li>Color - {data.facts && data.facts.Color} </li>
                    <li>Quality - {data.facts && data.facts.Quality} </li>
                    <li>Day - {data.facts && data.facts.Day} </li>
                    <li>Ruler - {data.facts && data.facts.Ruler} </li>
                    <li>Compatibility - {data.facts && data.facts.Compatibility} </li>
                    <li>Luckynum - {data.facts && data.facts.Luckynum} </li>
                    <li>Dates - {data.facts && data.facts.Dates} </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography style={{ fontWeight: 'bold' }}>Traits</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <ul>
                    <li>Strengths <br></br><br></br> {data.traits && data.traits.Strengths} </li>
                    <br></br>
                    <li>Weaknesses <br></br><br></br> {data.traits && data.traits.Weaknesses} </li>
                    <br></br>
                    <li>Likes <br></br><br></br> {data.traits && data.traits.Likes} </li>
                    <br></br>
                    <li>Dislikes <br></br><br></br> {data.traits && data.traits.Dislikes} </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography style={{ fontWeight: 'bold' }}>General</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography style={{ whiteSpace: 'pre-line' }}>{data.general}</Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
                <Typography style={{ fontWeight: 'bold' }}>Love</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography style={{ whiteSpace: 'pre-line' }}>{data.love}</Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5a-content" id="panel5a-header">
                <Typography style={{ fontWeight: 'bold' }}>{data.name} with Friends</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography style={{ whiteSpace: 'pre-line' }}>{data.friends}</Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel6'} onChange={handleAccordionChange('panel6')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6a-content" id="panel6a-header">
                <Typography style={{ fontWeight: 'bold' }}>{data.name} with Family</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography style={{ whiteSpace: 'pre-line' }}>{data.family}</Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel7'} onChange={handleAccordionChange('panel7')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7a-content" id="panel7a-header">
                <Typography style={{ fontWeight: 'bold' }}>Career</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography style={{ whiteSpace: 'pre-line' }}>{data.career}</Typography>
              </AccordionDetails>
            </Accordion>


            <Accordion expanded={expanded === 'panel9'} onChange={handleAccordionChange('panel9')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9a-content" id="panel9a-header">
                <Typography style={{ fontWeight: 'bold' }}>Money</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography style={{ whiteSpace: 'pre-line' }}>{data.money}</Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel10'} onChange={handleAccordionChange('panel10')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel10a-content" id="panel10a-header">
                <Typography style={{ fontWeight: 'bold' }}>How to attract {data.name} man </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography style={{ whiteSpace: 'pre-line' }}>{data["to attract man"]}</Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel11'} onChange={handleAccordionChange('panel11')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel11a-content" id="panel11a-header">
                <Typography style={{ fontWeight: 'bold' }}>How to attract {data.name} woman </Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Typography style={{ whiteSpace: 'pre-line' }}>{data["to attract woman"]}</Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        )
      )}
    </div>
  );
};

export default function WesternZodiacReading() {
  return (
    <div>
      <ResponsiveAppBar />
      <WesternZodiac />
      <Footer/>
    </div>
  );
}
