import React from 'react';
import Box from '@mui/material/Box';
import ResponsiveAppBar from './Appbar'; 
import Footer from './Footer'; 
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


const Video = () => {
    
return (
    <div>
   
   <h3 style={{marginTop:"100px",marginLeft:"40px"}} class="uk-text-bold uk-title">Video Reading</h3> 
            <Box sx={{ flexGrow: 1, margin: '10rem' }}>
            
             <React.Fragment>
    <CardContent>
      
      <Typography variant="h5" component="div">
      Stay Tuned for Updates, Video Readings Are Coming Your Way!
      </Typography>
      <br></br>
      <Typography variant="h6">
        Thank You
      </Typography>
    </CardContent>
   
  </React.Fragment>
  
            </Box>
</div>
                
               
               
             
                );
            };
            
            
            
            
            export default function VideoReading() {
                return (
                    <div>
                    <ResponsiveAppBar />   
                    <Video />
                    <Footer/>
                    </div>
                    );
                }
                