import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import ResponsiveAppBar from './Appbar';
import Footer from './Footer';
import Preloader from './Preloader';
import { useParams } from 'react-router-dom';


const PickACardPostRes = () => {

  const { index } = useParams();
  //const lang = localStorage.getItem('selectedLanguage');
  const lang = "mm";
  const [title, setTitle] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const pick = localStorage.getItem('pickAcard');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}pickacard.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setTitle(result.card[index].name);setData(result.card[index].type[pick]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);setLoading(true);
      }
    };
    fetchData();
  }, [lang,index,pick]);
 

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        data && (
          <div class="uk-card uk-card-default uk-width-1-1@m" style={{"marginTop":"70px"}}>
          <div class="uk-card-header">
            <h3 class="uk-text-bold">{title}</h3>
              <div class="uk-grid-small" uk-grid>
                  <div style={{"display": "flex","justify-content": "center","align-items": "center"}}>
                      <img  width="35%" height="35vh" src={data.img} alt={data.title}/>
                  </div>
                  <div class="uk-width-expand uk-text-center">
                      <h4 class="uk-card-title uk-margin-remove-bottom uk-margin-top">{data.title}</h4>
                  </div>
              </div>
          </div>
          <div class="uk-card-body">
              <Typography dangerouslySetInnerHTML={{ __html: data.meaning.replace(/\n/g, '<br><br>') }} />
          </div>
          <div class="uk-card-footer">
              <button class="uk-button uk-button-text">Share</button>
          </div>
      </div>
        )
      )}
    </div>
  );
};

export default function PickACardPostResReading() {
  return (
    <div>
      <ResponsiveAppBar />
      <PickACardPostRes />
      <Footer />
    </div>
  );
}
