import React from 'react';
import ResponsiveAppBar from './Appbar';
import Footer from './Footer';

const CookiePolicy = () => {
  const sectionStyle = {
    maxWidth: '1100px',
    padding: '20px', 
  };

  const headingStyle = {
    fontSize: '24px', 
    marginBottom: '20px', 
  };

  const paragraphStyle = {
    marginBottom: '15px', 
  };

  return (
    <div class="uk-margin-left" style={{marginTop:"100px"}}>
      <div className="w3-top">
        <div className="w3-bar w3-white w3-padding w3-card" style={{ letterSpacing: '4px' }}>
          <h5>This is the Cookie Policy for this Website which is accessible</h5>
        </div>
      </div>

      <div className="w3-content" style={sectionStyle}>
        <div className="w3-row w3-padding-64" id="about">
          <h1 className="w3-center" style={headingStyle}>
            Cookie Policy
          </h1>
          <div className="w3-content" style={sectionStyle}>
            <div className="w3-row w3-padding-64" id="about">
              <h1 className="" style={headingStyle}>
                What are cookies?
              </h1>
              <div className="w3-col">
                <p className="" style={paragraphStyle}>
                  This site, like almost all professional websites, uses cookies, which are small files that are
                  downloaded to your computer, to improve your experience. This page explains what information they
                  collect, how we use it, and why we occasionally need to store these cookies. We will also explain how
                  you can prevent these cookies from being stored, though doing so may reduce or 'break' certain aspects
                  of the site's functionality.
                </p>
              </div>
            </div>
          </div>

          <div className="w3-content" style={sectionStyle}>
            <div className="w3-row w3-padding-64" id="about">
              <h1 className="" style={headingStyle}>
                How We Use Cookies?
              </h1>
              <div className="w3-col">
                <p className="" style={paragraphStyle}>
                  We use cookies for a number of reasons, which are detailed below. Unfortunately, there are no industry
                  standard options for disabling cookies without completely disabling the functionality and features
                  they add to this site in the majority of cases. If you are unsure whether you require cookies, it is
                  recommended that you leave them enabled in case they are used to provide a service that you use.
                </p>
              </div>
            </div>
          </div>

          <div className="w3-content" style={sectionStyle}>
            <div className="w3-row w3-padding-64" id="about">
              <h1 className="" style={headingStyle}>
                Disabling Cookies
              </h1>
              <div className="w3-col">
                <p className="" style={paragraphStyle}>
                  You can prevent cookies from being set by adjusting your browser settings (see your browser Help for
                  how to do this). Please be aware that disabling cookies will have an impact on the functionality of
                  this and many other websites you visit. Disabling cookies usually results in the loss of certain
                  functionality and features on this site. As a result, it is not recommended that you disable cookies.
                </p>
              </div>
            </div>
          </div>

          <div className="w3-content" style={sectionStyle}>
            <div className="w3-row w3-padding-64" id="about">
              <h1 className="" style={headingStyle}>
                The Cookies We Set
              </h1>
              <div className="w3-col">
                <li>
                  <strong>Site preferences cookies</strong>
                </li>
                <p className="" style={paragraphStyle}>
                  We provide the functionality to set your preferences for how this site runs when you use it in order
                  to provide you with a great experience. To remember your preferences, we need to set cookies so that
                  this information can be called whenever you interact with a page that is affected by your preferences.
                </p>
              </div>
            </div>
          </div>

          <div className="w3-content" style={sectionStyle}>
            <div className="w3-row w3-padding-64" id="about">
              <h1 className="" style={headingStyle}>
                Third Party Cookies
              </h1>
              <div className="w3-col">
                <p className="" style={paragraphStyle}>
                  Cookies provided by trusted third parties are also used in some special cases. The section that
                  follows describes which third-party cookies you may encounter while browsing this site.
                </p>
                <ul>
                  <li>
                    <p>
                      This site uses Google Analytics which is one of the most widespread and trusted analytics
                      solution on the web for helping us to understand how you use the site and ways that we can
                      improve your experience. These cookies may track things such as how long you spend on the site
                      and the pages that you visit so we can continue to produce engaging content.
                    </p>
                    <p>For more information on Google Analytics cookies, see the official Google Analytics page.</p>
                  </li>

                  <li>
                    <p>
                      Third party analytics are used to track and measure usage of this site so that we can continue
                      to produce engaging content. These cookies may track things such as how long you spend on the
                      site or pages you visit which helps us to understand how we can improve the site for you.
                    </p>
                  </li>

                  <li>
                    <p>
                      From time to time we test new features and make subtle changes to the way that the site is
                      delivered. When we are still testing new features these cookies may be used to ensure that you
                      receive a consistent experience whilst on the site whilst ensuring we understand which
                      optimisations our users appreciate the most.
                    </p>
                  </li>

                  <li>
                    <p>
                      The Google AdSense service we use to serve advertising uses a DoubleClick cookie to serve more
                      relevant ads across the web and limit the number of times that a given ad is shown to you.
                    </p>
                    <p>For more information on Google AdSense see the official Google AdSense privacy FAQ.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="w3-content" style={sectionStyle}>
            <div className="w3-row w3-padding-64" id="about">
              <h1 className="" style={headingStyle}>
                More Information
              </h1>
              <div className="w3-col">
                <p className="" style={paragraphStyle}>
                  Hopefully, this has clarified things for you, and as previously stated, if you're not sure whether you
                  need something, it's usually safer to leave cookies enabled in case it interacts with one of the
                  features you use on our site.
                </p>
                <p>However, if you are still looking for more information, then you can contact us through one of our
                  preferred contact methods:</p>
                <ul>
                  <li>
                    <strong>Email: <a href="/cdn-cgi/l/email-protection"
                        className="__cf_email__"
                        data-cfemail="cebdbbbebea1bcba8eb6babda3a3e0ada1a3">support@xtsmm.com</a></strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Cookies() {
  return (
    <div>
      <ResponsiveAppBar />
      <CookiePolicy />
      <Footer />
    </div>
  );
}
