import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ResponsiveAppBar from './Appbar'; 
import Footer from './Footer'; 
import Preloader from './Preloader';
import { motion } from "framer-motion";

const TarotCardList = () => {
    const lang = localStorage.getItem('selectedLanguage');
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
  
    
    const gotoTarotCardMeaning = (index) => {
    window.location.href = "/home/tarot/tarot-cards-meaningdetails/" + index;
    };
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}majorcards.json`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                const cardNames = Object.values(result.tarot_deck).map(card => card.name);
                setData(cardNames);setLoading(false);
                
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(true);
            }
        };
        
        fetchData();
    }, [lang]);
    
    
    return (
        <div>
        {isLoading ? (
            <Preloader />
            ) : (
                <Box sx={{ flexGrow: 1, margin:15}}>
                    <Typography sx={{ mt: 4, mb: 4 }} variant="h4" component="div">
                    Major Arcana Tarot Card Meanings
                </Typography>
              
                   <Grid container spacing={4}>  
                   {data.map((cardName, index) => (
                            <Grid item xs={12} md={3} key={index}>
                                <motion.div whileHover={{ scale: [null, 1.12, 1.1]}}transition={{ duration: 0.3 }}>
                                <div  onClick={() => gotoTarotCardMeaning(index)} style={{ display: 'flex', alignItems: 'center',cursor:'pointer',transition: "color 0.3s", ":hover": { color: "black" } }}>
                                <KeyboardDoubleArrowRightIcon style={{transition: "color 0.3s", ":hover": { color: "black" }}}  />
                                <Typography style={{marginLeft:"8px"}}variant="body1">{cardName}</Typography>
                                </div>
                                </motion.div>
                            </Grid>
                        ))}
                </Grid>
                </Box>
                )}
                </div>
                );
            };
            
            
            export default function TarotCardListReading() {
                return (
                    <div>
                    <ResponsiveAppBar />   
                    <TarotCardList />
                    <Footer/>
                    </div>
                    );
                }
                