import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ResponsiveAppBar from './Appbar'; 
import Footer from './Footer'; 
import Preloader from './Preloader';


const Astrology = () => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = localStorage.getItem('selectedLanguage');
    const gotoAstrologyDetails = (index) => {
    window.location.href = "/home/astrologydetails/"+index;
    };
    
 
useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/mmastrology.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result.reverse());
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);setLoading(true);
      }
    };
    fetchData();
  }, [lang]);
  

return (
    <div>
    {isLoading ? (
        <Preloader />
        ) : (
            <Box sx={{ flexGrow: 1, margin: '6rem' }}>
             <h3 class="uk-text-bold uk-title">Astrology Reading</h3>   
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 6, md: 6 }}>
            {data.map((item, index) => (
                <Grid item xs={6} sm={6} md={3} key={item}>
                    
                <div onClick={() => gotoAstrologyDetails(data.length - index -1)} class="uk-card uk-card-hover uk-card-default uk-card-body" style={{"border-radius":"1rem"}}>
    <div class="uk-width-auto uk-float-right">
        <img style={{"borderRadius":"5%"}} width="100" height="100" src={item.img} alt={item.title}/>
    </div>
    <div class="uk-width-expand">
        <h4 class="uk-margin-remove-bottom homepghead1">{item.title}</h4>
        <p class="uk-text-meta homepgtext1">{item.about.slice(0, 100)} ...</p>
    </div>
</div>
                </Grid>
                ))}
                </Grid>
                </Box>
                )}
                </div>
                );
            };
            
            
            
            
            export default function AstrologyReading() {
                return (
                    <div>
                    <ResponsiveAppBar />   
                    <Astrology />
                    <Footer/>
                    </div>
                    );
                }
                