import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import ResponsiveAppBar from './Appbar'; 
import Footer from './Footer'; 
import Preloader from './Preloader';
import { motion } from "framer-motion"; 



const SlideshowComponent = () => {
  const [astrologyData, setAstrologyData] = useState([]);

  const gotoAstrologyDetails = (index) => {
    window.location.href = "/home/astrologydetails/"+index;
    };
  useEffect(() => {
    // Fetch the JSON data from the URL
    fetch('https://blackwhispers.xtechmm.workers.dev/js/mmastrology.json')
      .then(response => response.json())
      .then(data => setAstrologyData(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div style={{backgroundColor:"#111",marginTop:"50px"}} className="uk-position-relative uk-visible-toggle" tabIndex="-1" uk-slideshow="animation: push; autoplay: true; autoplay-interval: 3000">
    <ul className="uk-slideshow-items">
      {astrologyData.map((item, index) => (
        <li style={{cursor:"pointer"}} key={index} onClick={() => gotoAstrologyDetails(index)}>
          <img className='uk-position-center' src={item.img} alt="" style={{width: "auto", height: "auto"}}/>
          <div className="uk-overlay uk-overlay-primary uk-position-bottom uk-text-center uk-visible@m">
          <h4 className="uk-margin-remove">{item.title}</h4>
          <p className="uk-margin-remove">
            {item.about.split(' ').slice(0, 5).join(' ')}
            {item.about.split(' ').length > 5 ? '...' : ''}
          </p>
        </div>
        </li>
      ))}
    </ul>
  
    <div className="uk-light">
      {/* <a className="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
      <a className="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a> */}
    </div>
  </div>
  
  );

};

const MainCatCards = () => {
  const [data, setData] = useState([]);
  const [path, setPath] = useState([]);
  const [isLoading, setLoading] = useState(true);


  const lang = localStorage.getItem('selectedLanguage') === null ? "mm" : localStorage.getItem('selectedLanguage');
  const dob = localStorage.getItem('baydinwebdob');
  useEffect(() => {
    const fetchData = async () => {
      if (dob === null) {window.location.href = "/login";}
      else{
      try {
        const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}maincategory.json`);
        if (!response.ok) {throw new Error('Network response was not ok');}
        const result = await response.json();
        setData(result.maincategory);setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);setLoading(true);
      }}
    };
    fetchData();
  }, [dob,lang]);

  useEffect(() => {
   const fetchPath = async () => {
      try {
        const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/engmaincategory.json`);
        if (!response.ok) {throw new Error('Network response was not ok');}
        const res = await response.json();
        setPath(res.maincategory);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchPath();
  }, []);
  
  const gotoSubCategory = (index) => {
      const next = path[index].title;
      window.location.href = "home/" + next.toLowerCase();
  };


  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        <Box sx={{ flexGrow: 1, margin: '6rem' }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {data.map((item, index) => (
              <Grid item xs={4} sm={4} md={4} key={item}>
               <motion.div whileHover={{ scale: 1.05 }}>
                <Card sx={{ maxWidth: 345 }} onClick={() => gotoSubCategory(index)}>
                  <CardActionArea>
                    <CardMedia 
                      component="img"
                      height="140"
                      image={item.logo}
                      alt={item.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {item.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {item.desc}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button onClick={() => gotoSubCategory(index)} size="small" color="primary">
                      Details
                    </Button>
                  </CardActions>
                </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </div>
  );
}




export default function Home() {
  return (
    <div>
    <ResponsiveAppBar />   
    <SlideshowComponent />
    <MainCatCards />
    <Footer/>
    </div>
  );
}