import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const Footer = () => {
    const [showFooter, setShowFooter] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
          setShowFooter(true);
        }, 3000); 
    
        return () => clearTimeout(timer); 
      }, []);
    return showFooter ? (
        <div style={{ marginTop:"100px"}}>
        <nav class="uk-background-secondary uk-light" uk-navbar>
        <div class="uk-container">
        <div class="uk-navbar-left">
        <ul class="uk-navbar-nav">
        <li class="uk-text-bold"><Link to="/CookiesPolicy">Cookies Policy</Link></li>
        <li class="uk-text-bold"><Link to="/PrivacyPolicy">Privacy Policy</Link></li>
        <li class="uk-text-bold"><Link to="/Terms&Conditions">Terms & Conditions</Link></li>
        </ul>
        </div>
        </div>
        </nav>
        </div>
        ): null;
    };
    
    export default function footer() {
        return (
            <div>
            <Footer />
            </div>
            );
        }
        