import React, { useState, useEffect } from 'react';
import ResponsiveAppBar from './Appbar';
import Footer from './Footer'; 
import Preloader from './Preloader';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import Divider from '@mui/material/Divider';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
function CircularProgressWithLabel(props: CircularProgressProps & { value: number },) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props}  sx={{ color: 'black'}} size={80}/>
      <Box sx={{
          top: 0,left: 0,bottom: 0,right: 0,position: 'absolute',display: 'flex',alignItems: 'center',justifyContent: 'center',
        }}>
        <Typography variant="caption"component="div" color="#222" fontWeight="900" fontSize="1.5rem">
          {`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
const ZodiacCompat = () => {
  const lang = localStorage.getItem('selectedLanguage') || 'mm';


  const [sign1, setSign1] = useState();
  const [sign2, setSign2] = useState();
  const [rate,setRate] = useState();
  const [summary,setSummary] = useState();
 

  const [isLoading, setLoading] = useState(false); 

  const [progress, setProgress] = useState(0);
  const [calculateClicked, setCalculateClicked] = useState(false);

  const parsedDob = localStorage.getItem('baydinwebdob')? localStorage.getItem('baydinwebdob').split('-'): [];
  const [dob, setdob] = useState(parsedDob.length === 3? dayjs(`${parsedDob[2]}-${parsedDob[1]}-${parsedDob[0]}`): dayjs());
  const [partnerdob, setPartnerDob] = useState(dayjs());

  
  const handleCalculateClick = () => {setCalculateClicked(true);};

  useEffect(() => {
    if (calculateClicked) {  
      const fetchData = async () => {
        if (dob === null || partnerdob === null) {
          setLoading(true);
          window.location.href = '/login';
        } else {
          try {
            const birthday1 = dayjs(dob).format('DD-MM-YYYY');
            const birthday2 = dayjs(partnerdob).format('DD-MM-YYYY');
            const response = await fetch(
              `https://us-central1-nfc-project-e3961.cloudfunctions.net/function-1/getcompatibilityZodiac?birthday1=${birthday1}&birthday2=${birthday2}`
            );
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setSign1(result.sign1);setSign2(result.sign2);
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(true);
          }
        }
      };
      fetchData();
    }
  }, [dob, partnerdob, calculateClicked]);
  useEffect(() => {if (sign2) {fetchRes(lang, sign1, sign2, setRate, setSummary, setLoading);}}, [lang, sign1, sign2]);
  useEffect(() => {
    if (rate) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= rate.replace('%', '') ? rate.replace('%', '') : prevProgress + 5));
      }, 700);
  
      return () => {
        clearInterval(timer);
      };
    }
  }, [rate]);
  
  const fetchRes = async (lang, sign1, sign2, setRate, setSummary, setLoading) => {
    try {
      const response = await fetch(
        `https://blackwhispers.xtechmm.workers.dev/js/${lang}zodiaccompatibility.json`
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const result = await response.json();
      const signs = result.sign;
  
    
  
      for (let a = 0; a < signs.length; a++) {
        const sign = signs[a];
        if (sign.name === sign1) {
       
          const matchSigns = sign.match.sign;
          const matchSummary = sign.match.summary;
          const matchRate = sign.match.rate;
          const index = matchSigns.indexOf(sign2);

          if (index !== -1) {
          setRate(matchRate[index]); setSummary(matchSummary[index]);setLoading(false);
          break; 
          }}}
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(true);
        }
      };
     
    


  return (
    <div>
      {isLoading && (
        <Preloader/>
      )}
      <div className="uk-margin-left uk-margin-right uk-container" style={{ marginTop: "100px" }}>
        <h3 className='uk-margin-top uk-text-bold'> Zodiac Compatibility</h3>
        <Box sx={{ width: '100%' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 3, md: 3 }}>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <h4>Your Birthday</h4>
              <Item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    id="standard-basic"
                    variant="standard"
                    style={{"margin-top":"10px"}}
                    label="Date of Birth"
                    value={dob}
                    onChange={(newDate) => {setdob(newDate);setRate(0);setProgress(0);}}
                    format="DD-MM-YYYY"
                  />
                </LocalizationProvider>
              </Item>
            </Grid>
            <Grid item xs={6}>
              <h4>Partner's Birthday</h4>
              <Item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    id="standard-basic"
                    variant="standard"
                    style={{"margin-top":"10px"}}
                    label="Date of Birth"
                    value={partnerdob}
                    onChange={(newDate) => {setPartnerDob(newDate);setRate(0);setProgress(0);}}
                    format="DD-MM-YYYY"
                  />
                </LocalizationProvider>
              </Item>
            </Grid>
          </Grid>
          <Grid mt={4} xs display="flex" justifyContent="center" alignItems="center">
            <Fab variant="extended" onClick={handleCalculateClick} >
              <FavoriteIcon sx={{ mr: 2 }}/>
              Calculate
            </Fab>
          </Grid>
       
          {rate !== undefined && summary !== undefined && !isLoading && (
          <div class="uk-container" style={{marginTop:"30px"}}>
            <Divider textAlign="center"> <CircularProgressWithLabel value={progress} /></Divider>
           
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
            <h4 class="uk-text-center">{sign1}</h4>
              
            </Grid>
            <Grid item xs={6}>
            <h4 class="uk-text-center">{sign2}</h4>
              
            </Grid>
          </Grid>
            
            <p class="uk-text-bold">Summary: {summary}</p>
          </div>
        )}
        </Box>
      </div>
    </div>
  );
};

export default function ZodiacCompatReading() {
  return (
    <div>
      <ResponsiveAppBar />
      <ZodiacCompat />
      <Footer/>
    </div>
  );
}
