import React, { useEffect, useState } from 'react';
import './Preloader.css';


const Preloader = () => {
  const [showPreloader, setShowPreloader] = useState(true);
  const [introText, setIntroText] = useState('');
  const txt = 'Black Whispers';

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPreloader(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let i = 0;
    let timeoutId;
    const speed = 150;

    if (showPreloader) {
      const typeWriter = () => {
        if (i < txt.length) {
          setIntroText(prevText => prevText + txt.charAt(i));
          i++;
          timeoutId = setTimeout(typeWriter, speed);
        }
      };

      typeWriter();

      return () => clearTimeout(timeoutId);
    }
  }, [showPreloader]);

  return showPreloader ? (
    <div className="preloader">
      <div className="spinner">
        <img src="https://www.blackwhispers.com/image/logo/app-logobgremove.png" alt="Preloader" />
      </div>
      <div className="intro-container">
        <span id="introtext">{introText}</span>
      </div>
    </div>
  ) : null;
};

export default Preloader;
